import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Seo from '../components/Seo'
import Helmet from '../components/Helmet'
import Photo from '../components/Photo'
import Bio from '../components/Bio'
import Share from '../components/Share'

import { themeStyles } from '../utils/theme'

class PlaceTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.markdownRemark')

    const slug = get(post, 'fields.slug')
    const title = get(post, 'frontmatter.title')
    const excerpt = get(post, 'excerpt')
    const thumbnailImage = get(post, 'frontmatter.featuredImage.childImageSharp.fluid.src')
    const fullThumbnailImageURL = `${this.props.location.origin}${thumbnailImage}`
    const imageResponsive = get(post, 'frontmatter.featuredImage.childImageSharp.fluid')

    let posts = get(this, 'props.data.allMarkdownRemark.edges') || []
    posts = posts.filter(p => {
      let places = get(p, 'node.frontmatter.places')
      if (places && places.length > 0) {
        return places.includes(title)
      }
      return false
    })

    return (
      <Layout location={this.props.location}>
        <Seo
          title={title}
          description={excerpt}
        />
        <Helmet
          description={excerpt}
          image={fullThumbnailImageURL}
          title={title}
          path={slug}
        />
        <div css={themeStyles.textPadding}>
          {imageResponsive && <Photo title={title} imageResponsive={imageResponsive}/> }
          <div css={{textAlign: 'center'}} dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr />
          <PostList posts={posts} />
        </div>
        <Bio />
        <Share slug={slug} title={title} excerpt={excerpt} />
      </Layout>
    )
  }
}

export default PlaceTemplate

export const pageQuery = graphql`
  query Place($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {fields: {slug: { regex: "/posts/"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            places
            animals
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            published
          }
        }
      }
    }
  }
`
